import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeEmpower.vue'
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Onboarding',
    name: 'Onboarding',
    component: () => import('../views/OnboardingEmpower.vue')
  },
  {
    path: '/Chat',
    name: 'Chat',
    component: () => import('../views/ChatEmpower.vue')
  },
  {
    path: '/Organizational',
    name: 'Organizational',
    component: () => import('../views/OrganizationalEmpower.vue')
  },
  {
    path: '/Team',
    name: 'Team',
    component: () => import('../views/TeamEmpower.vue')
  },
]
})

export default router
